(function ($) {
  Drupal.behaviors.gnavSearchV2 = {
    attach: function (context) {
      var $searchForm = $('.gnav-search', context);
      var $searchField = $("input[name='search']", $searchForm);
      var $gnavUtilSearch = $('.gnav-util--search-v2', context);
      var $trustmarkIcon = $('.trustmark-logo-div', context);
      var bps = Unison.fetch.all();
      var bp = Unison.fetch.now();
      var isDesktop = parseInt(bp.width, 10) >= parseInt(bps.landscape, 10);

      if ($trustmarkIcon.length && !Drupal.settings.navigation && !Drupal.settings.navigation.is_cr20_desktop_nav_refresh) {
        $gnavUtilSearch.addClass('has-trustmark');
        if (isDesktop) {
          $(window).trigger('gnavSearchForm.load');
        }
      }

      /**
       * Typeahead: clear all the things
       */
      $(document).on('endeca.typeahead.clearall', function (event, gnavCloseAction) {
        var $results = $('.gnav-search__suggestions-wrapper ul', $searchForm);
        var bps = Unison.fetch.all();
        var bp = Unison.fetch.now();
        var isDesktop = parseInt(bp.width, 10) >= parseInt(bps.landscape, 10);

        if (Drupal.settings.navigation && Drupal.settings.navigation.is_cr20_desktop_nav_refresh && isDesktop) {
          $searchField.removeAttr('style');
        }

        if (!Drupal.settings.common.search_feature_revamp) {
          // Reset everything
          $searchField.val('');
          if (isDesktop) {
            $searchField.removeAttr('style');
          }
          $results.empty();
          $searchForm.removeClass('gnav-search--suggestions gnav-search--no-suggestions');
        } else if (!gnavCloseAction) {
          $searchField.focus();
        }
      });

      /**
       * Typeahead: keyup event handler
       */
      $searchField.on('endeca.typeahead.keyup', function () {
        var $val = $searchField.val();

        if ($val.length < 1) {
          $(this).trigger('endeca.typeahead.clearall');
        }
      });

      /**
       * Typeahead: close event handler
       */
      $(document).on('endeca.typeahead.close', function () {
        $(this).trigger('endeca.typeahead.clearall');
      });

      /**
       * Typeahead: search complete event handler
       */
      $(document).on('endeca.typeahead.complete', function (e, hasResults) {
        if (!Drupal.settings.common.search_feature_revamp) {
          $searchForm.removeClass('gnav-search--suggestions gnav-search--no-suggestions');
        }
        if (hasResults) {
          $searchForm.addClass('gnav-search--suggestions');
        } else {
          $searchForm.addClass('gnav-search--no-suggestions');
        }
      });

      /**
       * GNav: someone is closing the main nav drop-down
       */
      $(document).on('gnav-util.close', function () {
        $(this).trigger('endeca.typeahead.clearall', [true]);
      });

      $searchField.keyup(function () {
        var $this = $(this);
        var $item = $this.parents('.gnav-util').first();

        if ($this.val().length > 0 && !$item.hasClass('active-util') && $.isFunction(Drupal.behaviors.gnav.open)) {
          if (Drupal.settings.common.search_feature_revamp) {
            $gnavUtilSearch.addClass('active-util');
          } else {
            Drupal.behaviors.gnav.open($item);
          }
          calculatePosition($this);
        }
        if ($this.val().length === 0 && $item.hasClass('active-util') && $.isFunction(Drupal.behaviors.gnav.toggle)) {
          Drupal.behaviors.gnav.toggle($item);
        }
        if (Drupal.settings.navigation && Drupal.settings.navigation.is_cr20_desktop_nav_refresh && isDesktop) {
          $(window)
            .off('scroll.gnavSearchV2')
            .on(
              'scroll.gnavSearchV2',
              _.throttle(function () {
                calculatePosition($searchField.first());
              }, 100)
            );
        }
      });

      if (Drupal.settings.navigation && Drupal.settings.navigation.is_cr20_desktop_nav_refresh) {
        $(window)
          .off('resize.gnavSearchV2')
          .on(
            'resize.gnavSearchV2',
            _.throttle(function () {
              $searchField.each(function () {
                calculatePosition($(this));
              });
            }, 100)
          );
      }

      Unison.on('change', function () {
        bp = Unison.fetch.now();
        isDesktop = parseInt(bp.width, 10) >= parseInt(bps.landscape, 10);
      });

      var calculatePosition = function ($searchField) {
        var $gnavUtilContainer = $searchField.parents('.gnav-util');
        var $searchIcon = $('.gnav-util__content-icon', $gnavUtilContainer);
        var $searchLink = $('.gnav-util__icon', $searchIcon);
        var margin_input;

        if (!$searchIcon.length || !$searchLink.length) {
          return;
        }
        margin_input = $(window).width() - ($searchIcon.offset().left + $searchIcon.width());
        if (Drupal.settings.navigation && Drupal.settings.navigation.is_cr20_desktop_nav_refresh) {
          margin_input = $(window).width() - ($searchLink.offset().left + $searchLink.width());
        } else if (!Drupal.settings.common.search_feature_revamp) {
          margin_input = $(window).width() - $searchIcon.position().left - $searchIcon.width();
        }
        var bps = Unison.fetch.all();
        var bp = Unison.fetch.now();
        var isDesktop = parseInt(bp.width, 10) >= parseInt(bps.landscape, 10);

        if ($gnavUtilContainer.hasClass('active-util') && isDesktop) {
          $searchField.css('right', margin_input).css('left', 'auto');
        }
      };
    }
  };
})(jQuery);
